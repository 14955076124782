import React from 'react'
import { MdRemove } from 'react-icons/md'

const History = () => {
  return (
    <div>History</div>
  )
 

}

export default History