import React from "react";
import ReactCardSlider from "react-card-slider-component";
import "./Gallery.css";
import { images } from '../../constants'
import SubHeading from '../../components/SubHeading/SubHeading'



function Gallery() {
  const handleSlideClick = (url) => {
    window.location = url;
  }
  const slides = [
    {
      image: "https://images.unsplash.com/photo-1504674900247-0877df9cc836?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Zm9vZHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
      title: "$5",
      description: "Food",
      clickEvent: () => handleSlideClick("https://www.google.com/"),
    },
    {
      image: "https://images.unsplash.com/photo-1496412705862-e0088f16f791?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjJ8fGZvb2R8ZW58MHwwfDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
      title: "$6",
      description: "Food",
      clickEvent: () => handleSlideClick("https://www.google.com/"),
    },
    {
      image: "https://images.unsplash.com/photo-1565299624946-b28f40a0ae38?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8Zm9vZHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
      title: "$8",
      description: "Food",
      clickEvent: ()=> handleSlideClick("https://www.google.com/")
    },
    {
      image: "https://images.unsplash.com/photo-1484723091739-30a097e8f929?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTZ8fGZvb2R8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",
      title: "4",
      description: "Food",
      clickEvent: ()=>handleSlideClick("https://www.google.com/")
    },
    {
      image: "https://images.unsplash.com/photo-1499028344343-cd173ffc68a9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTh8fGZvb2R8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",
      title: "$5",
      description: "Food",
      clickEvent: ()=>handleSlideClick("https://www.google.com/")
    },
    {
      image: "https://images.unsplash.com/photo-1476224203421-9ac39bcb3327?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjN8fGZvb2R8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",
      title: "$7",
      description: "Food",
      clickEvent: ()=>handleSlideClick("https://www.google.com/")
    },
    {
      image: "https://images.unsplash.com/photo-1476224203421-9ac39bcb3327?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTJ8fGZvb2R8ZW58MHwwfDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
      title: "$4",
      description: "Food ",
      clickEvent: ()=>handleSlideClick("https://www.google.com/")
    },
     
  ];
  return (
    <>
    <div className="app__gallery flex__center">
    <div className="app__gallery-content">
    <SubHeading title='Instagragm'/>
    <p className="p__opensans" style={{ color: '#AAAAAA', marginTop: '2rem' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat mattis ipsum turpis elit elit scelerisque egestas mu.</p>
        <button type="button" className="custom__button " style={{marginTop:'20px'}}>View More</button>
    </div>
    </div>
    <div className="side">
      <ReactCardSlider className="sdd" slides={slides} />
      </div>
    </>
  );
}


export default Gallery;
