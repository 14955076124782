import logo from './logo.svg';
import './App.css';
import './index.css'

// import { AboutUs, Chef, FindUs, Footer, Gallery, Header, Intro, Laurels, SpecialMenu } from './container';
import Navbar from './components/Navbar/Navbar';
import Header from './container/Header/Header';
import AboutUS from './container/AboutUs/AboutUS';
import SpecialMenu from './container/Menu/SpecialMenu';
import Chef from './container/Chef/Chef';
import Intro from './container/Intro/Intro';
import Laurels from './container/Laurels/Laurels';
import Gallery from './container/Gallery/Gallery';
import FindUs from './container/FindUs/FindUs';
import Footer from './container/Footer/Footer';
import History from './container/AboutUs/History';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


function App() {
  return (
    <div>
    <Router>
    <Routes>
          <Route path="/" element={<>
            <Navbar />
            <Header />
            <AboutUS />
            <SpecialMenu />
            <Chef />
            <Intro />
            <Laurels />
            <Gallery />
            <FindUs />
            <Footer />
          </>} />
          <Route path="/other-page" element={<History />} />
        </Routes>
      
    </Router>
 
 </div>
  );
}

export default App;
