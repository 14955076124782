import React from 'react'
import './Laurels.css'

import SubHeading from '../../components/SubHeading/SubHeading';
import { images, data } from '../../constants';
import MenuItem from '../../components/MenuItem/MenuItem';

const AwardCard = ({ award: { imgUrl, title, subtitle } }) => (
    <div className="app__laurels_awards-card">
      <img src={imgUrl} alt="awards" />
      <div className="app__laurels_awards-card_content">
        <p className="p__cormorant" style={{ color: '#DCCA87' }}>{title}</p>
        <p className="p__opensans">{subtitle}</p>
      </div>
    </div>
  );
  
  const Laurels = () => (
    <div className="app__bg app__wrapper section__padding" id="awards">
      <div className="app__wrapper_info">
        <SubHeading title="Awards & recognition" />
        <h1 className="headtext__cormorant">Our Laurels</h1>
  
        <div className="app__laurels_awards">
          {data.awards.map((award,key) => <AwardCard award={award} key={key} />)}
        </div>
      </div>
  
      <div className="app__wrapper_img">
        <img src={images.laurels} alt="laurels_img" />
      </div>
    </div>
  );

export default Laurels